import React from "react";
import { graphql } from "gatsby";
import BiographyTemplate from "./components/biography";
import Layout from "../components/Layout";
import Zoom from 'react-reveal/Zoom';


const Biography = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Zoom>
        <BiographyTemplate
          objects={post.frontmatter.objects}
          name={post.frontmatter.name}
          datesAndPlaces={post.frontmatter.datesAndPlaces}
          nameSound={post.frontmatter.nameSound}
          backgroundColor={post.frontmatter.backgroundColor}
        />
      </Zoom>
    </Layout>
  );
};

export default Biography;

export const pageQuery = graphql`
  query BiographyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        datesAndPlaces
        backgroundColor
        name
        nameSound
        objects {
          file
          image
          richText
          textLength
          fontSize
          caption
          width
          sources
          posX
          posY
        }
      }
    }
  }
`;
